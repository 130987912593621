import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isIos } from '../../../common/services/detect-platform';
import styles from './viewport-ios-container.scss';
import withCardBackgroundColor from '../../hoc/with-card-background-color';

class ViewportIosContainer extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    enabled: PropTypes.bool,
    dataHook: PropTypes.string,
    setRef: PropTypes.func,
    cardBackgroundColor: PropTypes.string.isRequired,
  };

  static defaultProps = {
    enabled: true,
  };

  state = { height: '100%' };

  componentDidMount() {
    if (isIos() && typeof window.visualViewport !== 'undefined') {
      this.setState({ height: Math.round(window.visualViewport.height) });
      window.visualViewport.addEventListener('resize', this.handleViewportResize);
      window.visualViewport.addEventListener('scroll', this.handleViewportResize);
    }
  }

  componentWillUnmount() {
    if (typeof window.visualViewport !== 'undefined') {
      window.visualViewport.removeEventListener('resize', this.handleViewportResize);
      window.visualViewport.removeEventListener('scroll', this.handleViewportResize);
    }
  }

  handleViewportResize = () => {
    if (!this.props.enabled) return;
    window.scrollY !== 0 && window.scrollBy(0, window.scrollY * -1); // compensate for ios autoscroll on input focus
    this.setState({ height: Math.round(window.visualViewport.height) });
  };

  render() {
    const { children, dataHook, className, cardBackgroundColor, setRef } = this.props;
    return (
      <div
        ref={setRef}
        className={styles.backdrop}
        style={{ backgroundColor: cardBackgroundColor }}
      >
        <div
          className={classNames(styles.container, className)}
          data-hook={dataHook || 'viewport-container'}
          style={{ height: this.state.height }}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default withCardBackgroundColor(ViewportIosContainer);
