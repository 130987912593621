import { flowRight, invoke, isNumber, debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import { MODAL_TYPE_DISCARD_COMMENT } from '../modals/discard-comment-modal/discard-comment-modal-type';
import { getElementsPositionToRootWindow } from '../../services/get-elements-position';
import { scrollBy } from '../../services/scroll-by';
import commentFormSettings from '../../services/comment-form-settings';
import { isContentStateEmpty } from '../../services/content-state-utils';
import CurrentUserAvatar from '../../containers/current-user-avatar';
import Button from '../button';
import ButtonGroup from '../button-group';
import TimeAgo from '../time-ago';
import withTranslate from '../../../common/components/with-translate/with-translate';
import Loader from '@wix/communities-forum-client-commons/dist/src/components/loader';
import withFontClassName from '../../hoc/with-font-class-name';
import withDividerColor from '../../hoc/with-divider-color';
import withAuth from '../../hoc/with-auth';
import ensureAuth from '../../hoc/ensure-auth';
import withExperiment from '../../hoc/with-experiment';
import RichContentEditor from '../rich-content-editor-async';
import getThemeForComment from '../rich-content-editor/theme-comment';
import getThemeForReply from '../rich-content-editor/theme-reply';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import { withFastForm } from '../../../common/components/fast-form';
import { getResolvedModals } from '../../../common/modals/framework/store/modal-selectors';
import { CREATE_COMMENT, CREATE_REPLY } from '../../constants/interactions';
import {
  EXPERIMENT_COMMENT_RATE_LIMITER,
  EXPERIMENT_RCE_LOADING_TYPE,
} from '@wix/communities-forum-client-commons/dist/src/constants/experiments';
import { COMMENT, REPLY } from '../../constants/form-types';
import { DISCUSSION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';
import { getIsRegistrationWithApproval } from '../../selectors/forum-data-selectors';
import { getCurrentUserRemainingPosts } from '../../../common/store/current-user/current-user-selectors';
import { COMMENT_LIMIT_REACHED } from '../messages/message-types';

import styles from './comment-form.scss';
import rceStyles from '../rich-content-editor/theme.scss';
import { getIsRCELoading } from '../../selectors/is-loading-selectors';
import { ON_EXPAND, ON_FOCUS, ON_LOAD } from '../../constants/rce-loading-types';
import { getEntityCharCount } from '../../services/post-utils';
import Interpolate from '../../../common/components/interpolate/interpolate';
import { withEditorContext } from 'wix-rich-content-editor-common/libs/EditorEventsContext';

export const SIZE_COMMENT = 'sizeComment';
export const SIZE_REPLY = 'sizeReply';

const createEditorKey = () => new Date().getTime();

const SecureButton = ensureAuth(Button);
export class CommentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorKey: createEditorKey(),
      discardModalId: Date.now(),
      isRCEMounted: false,
      isRCELoaded: false,
      showLoader: false,
    };
    this.tempInput = '';
    this.isTyping = false;
    this.shouldFocusRCE = false;

    if ((props.isLoadRCEOnFocus || props.isLoadRCEOnExpand) && props.instantRCE) {
      this.state.showLoader = true;
      this.loadRCE();
    }
  }

  componentDidMount = () =>
    this.props.scrollIntoView &&
    this.selfElement &&
    getElementsPositionToRootWindow(this.selfElement, { center: true }, y => {
      setTimeout(() => scrollBy(0, y), 100);
    });

  loadRCE() {
    RichContentEditor.load().then(() => this.setState({ isRCELoaded: true, showLoader: false }));
  }

  componentDidUpdate(prevProps) {
    const props = this.props;
    if (props.resolvedModals.find(({ resolve: { id } }) => id === this.state.discardModalId)) {
      this.setState({ discardModalId: Date.now() });
      this.onCancel();
    }
    if (prevProps.fastForm.values.content && !props.fastForm.values.content) {
      this.setState({
        editorKey: createEditorKey(),
      });
    }
    if (props.isAuthenticated && !prevProps.isAuthenticated) {
      props.fastForm.resetForm();
    }
    if (!prevProps.shouldLoadRCE && props.shouldLoadRCE) {
      this.loadRCE();
    }
  }

  reportBi = () => {
    const {
      parentCommentId,
      userEventsClickPublish,
      fastForm,
      isReply,
      postId,
      isEditing,
    } = this.props;
    const comment = fastForm.values;
    userEventsClickPublish({
      type: isReply ? REPLY : COMMENT,
      symbolCount: getEntityCharCount(comment),
      postId,
      parentId: parentCommentId,
      isEditing,
    });
  };

  handleSubmitButton = async () => {
    const { isCommentLimiterEnabled, remainingPosts, showMessage, postType, isReply } = this.props;
    const isDiscussion = postType === DISCUSSION;

    if (isCommentLimiterEnabled && isNumber(remainingPosts) && remainingPosts < 1) {
      showMessage(COMMENT_LIMIT_REACHED, { isReply, isDiscussion });
    } else {
      if (this.props.editorEvents.publish) {
        const content = await this.props.editorEvents.publish();
        this.props.fastForm.changeValue('content')(content);
      }
      this.props.fastForm.submit();
    }
  };

  handleContentChange = contentState => {
    this.props.fastForm.changeValue('content')(contentState);

    if (contentState && !isContentStateEmpty(contentState)) {
      invoke(this.props, 'onChange');
    }
  };

  renderEditDate = () => {
    const { editedDate } = this.props;

    if (!editedDate) {
      return null;
    }

    return (
      <p className={styles.editDate}>
        <Interpolate i18nKey="comment-form.edited" timeAgo={<TimeAgo time={editedDate} />} />
      </p>
    );
  };

  onCancel = () => {
    if (this.props.resetFormOnCancel) {
      this.props.fastForm.resetForm();
    }
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  isPrimaryButtonDisabled = () => {
    const {
      fastForm: { isSubmitting, isValid },
      shouldDisableButton,
    } = this.props;

    return shouldDisableButton ? isSubmitting || !isValid : false;
  };

  isSecondaryButtonVisible = () => {
    const { alwaysShowCancelButton, resetFormOnCancel, onCancel } = this.props;
    return (
      alwaysShowCancelButton ||
      (!this.isPrimaryButtonDisabled() && (resetFormOnCancel || Boolean(onCancel)))
    );
  };

  renderButtons = () => {
    const {
      t,
      forPublicUser,
      openModal,
      fastForm,
      isRegistrationWithApproval,
      isAuthenticated,
      postId,
      parentCommentId,
      postType,
      type,
      isReply,
    } = this.props;
    const primaryButtonDisabled = this.isPrimaryButtonDisabled();
    const primaryButtonProps = {
      disabled: primaryButtonDisabled,
      ...(fastForm.isSubmitting && { 'aria-label': t('comment-form.submitting') }),
      reportBI: this.reportBi,
      onClick: forPublicUser(this.handleSubmitButton),
      isSmall: true,
      isLoading: fastForm.isSubmitting,
      tooltipText: fastForm.errors.content === 'uploading' && t('post-form.uploading'),
      actionDetails:
        type === REPLY
          ? {
              action: CREATE_REPLY,
              args: [postId, parentCommentId, fastForm.values],
            }
          : {
              action: CREATE_COMMENT,
              args: [postId, fastForm.values],
            },
    };

    const showDiscardModal = ({ postType, isReply }) =>
      openModal(MODAL_TYPE_DISCARD_COMMENT, {
        id: this.state.discardModalId,
        postType,
        isReply,
      });

    return (
      <ButtonGroup
        primaryButtonComponent={SecureButton}
        primaryButtonText={t(
          postType === DISCUSSION || isReply ? 'comment-form.publish' : 'comment-form.answer',
        )}
        primaryButtonProps={primaryButtonProps}
        secondaryButtonText={t('comment-form.cancel')}
        secondaryButtonProps={{
          onClick: () => showDiscardModal({ postType, isReply }),
          isSmall: true,
        }}
        isSecondaryButtonVisible={
          isRegistrationWithApproval
            ? isAuthenticated && this.isSecondaryButtonVisible()
            : this.isSecondaryButtonVisible()
        }
      />
    );
  };

  onType = debounce(
    () => {
      this.isTyping = false;
      if (this.setContent) {
        this.setRCEContent();
      }
    },
    400,
    { trailing: true },
  );

  setRCEContent = () => {
    this.setContent(() => this.tempInput).then(() => (this.tempInput = ''));
    if (!this.state.isRCEMounted) {
      this.setState({
        isRCEMounted: true,
      });
    }
  };

  setEditorRef = ref => {
    this.editorRef = ref;
  };

  renderTextEditor() {
    const {
      fastForm: { values },
      t,
      size,
      postType,
      onFocus,
      isLoadRCEOnFocus,
      scrollIntoView,
    } = this.props;

    const { isRCELoaded, isRCEMounted, editorKey } = this.state;

    const placeholder = t(
      size === SIZE_REPLY
        ? 'text-editor.reply-placeholder'
        : postType === DISCUSSION
        ? 'text-editor.comment-placeholder'
        : 'text-editor.answer-placeholder',
    );

    return (
      <div
        className={classNames(styles.rceContainer, {
          [rceStyles.smallFooterToolbar]: this.isSecondaryButtonVisible(),
        })}
      >
        {isLoadRCEOnFocus ? (
          <React.Fragment>
            {isRCELoaded && (
              <div className={isRCEMounted ? '' : styles.rceHidden}>
                <RichContentEditor
                  key={editorKey}
                  setRef={this.setEditorRef}
                  placeholder={placeholder}
                  onChange={this.handleContentChange}
                  initialState={values.content || undefined}
                  themeGetter={size === SIZE_COMMENT ? getThemeForComment : getThemeForReply}
                  externalModalsEnabled
                  focus={(this.shouldFocusRCE && !this.isTyping) || scrollIntoView}
                  onFocus={onFocus}
                  origin="comment"
                  onMount={setContent => {
                    this.setContent = setContent;
                    if (!this.isTyping) {
                      setTimeout(this.setRCEContent, 50);
                    }
                  }}
                />
              </div>
            )}
            {!isRCEMounted && (
              <textarea
                onFocus={() => {
                  this.shouldFocusRCE = true;
                  this.loadRCE();
                }}
                onBlur={() => (this.shouldFocusRCE = false)}
                onChange={event => {
                  this.tempInput = event.target.value;
                  this.isTyping = true;
                  this.onType();
                }}
                placeholder={placeholder}
                className={styles.textarea}
              />
            )}
          </React.Fragment>
        ) : (
          <RichContentEditor
            key={this.state.editorKey}
            setRef={this.setEditorRef}
            placeholder={placeholder}
            onChange={this.handleContentChange}
            initialState={values.content || undefined}
            themeGetter={size === SIZE_COMMENT ? getThemeForComment : getThemeForReply}
            externalModalsEnabled
            focus={scrollIntoView}
            onFocus={onFocus}
            origin="comment"
          />
        )}
      </div>
    );
  }

  renderExpandableEditor = () => {
    const {
      fastForm: { values },
      t,
      size,
      postType,
      onFocus,
      scrollIntoView,
    } = this.props;

    const { isRCELoaded, showLoader, editorKey } = this.state;

    const placeholder = t(
      size === SIZE_REPLY
        ? 'text-editor.reply-placeholder'
        : postType === DISCUSSION
        ? 'text-editor.comment-placeholder'
        : 'text-editor.answer-placeholder',
    );

    if (showLoader) {
      return <Loader />;
    } else if (isRCELoaded) {
      return (
        <RichContentEditor
          key={editorKey}
          setRef={this.setEditorRef}
          placeholder={placeholder}
          onChange={this.handleContentChange}
          initialState={values.content || undefined}
          themeGetter={size === SIZE_COMMENT ? getThemeForComment : getThemeForReply}
          externalModalsEnabled
          focus={scrollIntoView || this.shouldFocusRCE}
          onFocus={onFocus}
          origin="comment"
        />
      );
    } else {
      return <span>{placeholder}</span>;
    }
  };

  renderExpandableTextEditor = () => {
    const { isRCELoaded } = this.state;
    return (
      <div
        className={classNames({
          [styles.expandable]: !isRCELoaded,
          [rceStyles.smallFooterToolbar]: this.isSecondaryButtonVisible(),
        })}
        onClick={() => {
          if (!isRCELoaded) {
            this.shouldFocusRCE = true;
            this.setState({ showLoader: true });
            this.loadRCE();
          }
        }}
      >
        {this.renderExpandableEditor()}
      </div>
    );
  };

  render = () => {
    const {
      contentFontClassName,
      dividerColor,
      size,
      className,
      isAuthenticated,
      isLoadRCEOnExpand,
    } = this.props;
    const containerClassName = classNames(
      styles.commentForm,
      className,
      contentFontClassName,
      styles[size],
      'forum-text-color',
      'comment-form',
    );
    return (
      <div
        className={containerClassName}
        data-hook="comment-form"
        ref={r => (this.selfElement = r)}
      >
        {isAuthenticated && (
          <div className={styles.header}>
            <CurrentUserAvatar type={CurrentUserAvatar.POST_PAGE} showUserInfoInline />
          </div>
        )}
        {this.renderEditDate()}
        <div className={classNames(styles.content)} style={{ borderColor: dividerColor }}>
          {isLoadRCEOnExpand ? this.renderExpandableTextEditor() : this.renderTextEditor()}
        </div>
        {isLoadRCEOnExpand && !this.state.isRCELoaded ? null : (
          <div className={styles.buttonContainer}>{this.renderButtons()}</div>
        )}
      </div>
    );
  };
}

CommentForm.propTypes = {
  onChange: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  submitting: PropTypes.bool,
  t: PropTypes.func,
  contentFontClassName: PropTypes.string.isRequired,
  className: PropTypes.string,
  shouldDisableButton: PropTypes.bool,
  editedDate: PropTypes.string,
  forPublicUser: PropTypes.func,
  dividerColor: PropTypes.string,
  size: PropTypes.string,
  resetFormOnCancel: PropTypes.bool,
  alwaysShowCancelButton: PropTypes.bool,
  scrollIntoView: PropTypes.bool,
  resolvedModals: PropTypes.array,
  openModal: PropTypes.func,
  fastForm: PropTypes.object.isRequired,
  postType: PropTypes.string,
  type: PropTypes.string,
  isReply: PropTypes.bool,
  isRegistrationWithApproval: PropTypes.bool,
  remainingPosts: PropTypes.number,
  showMessage: PropTypes.func,
  isCommentLimiterEnabled: PropTypes.bool,
  isLoadRCEOnFocus: PropTypes.bool,
  shouldLoadRCE: PropTypes.bool,
  instantRCE: PropTypes.bool,
  isLoadRCEOnExpand: PropTypes.bool,
  RCELoadingType: PropTypes.oneOf([ON_LOAD, ON_FOCUS, ON_EXPAND]),
  userEventsClickPublish: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
};

CommentForm.defaultProps = {
  shouldDisableButton: true,
  size: SIZE_COMMENT,
  postType: DISCUSSION,
  type: COMMENT,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  isReply: ownProps.type === REPLY,
  resolvedModals: getResolvedModals(state),
  openModal: actions.openModal,
  isRegistrationWithApproval: getIsRegistrationWithApproval(state),
  remainingPosts: getCurrentUserRemainingPosts(state),
  showMessage: actions.showMessage,
  shouldLoadRCE: getIsRCELoading(state),
  isLoadRCEOnExpand: ownProps.RCELoadingType === ON_EXPAND,
  isLoadRCEOnFocus: ownProps.RCELoadingType === ON_FOCUS,
  userEventsClickPublish: actions.userEventsClickPublish,
});

export default flowRight(
  withFastForm(commentFormSettings),
  withExperiment({
    isCommentLimiterEnabled: EXPERIMENT_COMMENT_RATE_LIMITER,
  }),
  withExperiment(
    {
      RCELoadingType: EXPERIMENT_RCE_LOADING_TYPE,
    },
    true,
  ),
  connect(mapRuntimeToProps, [REDUCERS.CURRENT_USER, REDUCERS.FORUM_DATA, REDUCERS.MODAL]),
  withTranslate,
  withFontClassName,
  withFontClassName,
  withDividerColor,
  withAuth,
  withEditorContext,
)(CommentForm);
