import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TimeAgo from '../time-ago';
import Link from '../link/internal-link';
import UserAvatarImage from '../user-avatar-image';
import MemberCardAsync from '../../containers/member-card-async';
import { connect } from '../../../common/components/runtime-context';
import {
  RECENT_ACTIVITY_REPLY,
  RECENT_ACTIVITY_COMMENT,
} from '../../constants/recent-activity-types';
import styles from './post-list-item-recent-activity.scss';
import AvatarImage from '../avatar-image';
import { POST_LIST_ITEM_RECENT_ACTIVITY } from '../../constants/bi-locations';
import { withErrorBoundary } from '@wix/communities-forum-client-commons/dist/src/components/with-error-boundary';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { ArrowLeftIcon } from '../icons/arrow-left-icon';
import { ArrowRightIcon } from '../icons/arrow-right-icon';
import withDirection, { DIRECTIONS } from 'react-with-direction';

class PostListItemRecentActivity extends Component {
  render() {
    const {
      post,
      category,
      resolveCommentDeepLinkUrl,
      direction,
      withDirectionIcon,
      withText,
      isAvatarSmall,
      t,
    } = this.props;

    let date;
    let user;
    let url;
    let dynamicLocationResolver;

    const hasRecentActivity = post.recentActivity && post.recentActivity.activityDate;
    if (hasRecentActivity) {
      date = post.recentActivity.activityDate;
      user = post.recentActivity.initiator;
      url = `/main/comment/${post.recentActivity.entityId}`;
      dynamicLocationResolver = () =>
        [RECENT_ACTIVITY_COMMENT, RECENT_ACTIVITY_REPLY].includes(post.recentActivity.type) &&
        resolveCommentDeepLinkUrl(post.recentActivity.entityId);
    } else {
      date = post.lastActivityDate || post.createdDate;
      user = post.owner;
      url = `/${category.slug}/${post.slug}`;
    }

    return (
      <Link
        to={url}
        asClickHandler={!hasRecentActivity}
        dynamicLocationResolver={dynamicLocationResolver}
        className={classNames('forum-text-color', 'forum-link-hover-color', styles.link)}
        biLocation={POST_LIST_ITEM_RECENT_ACTIVITY}
        data-hook="post-list-item__recent-activity"
        aria-label={t('post-list-item.recent-activity-nav-a11y')}
      >
        <MemberCardAsync
          viewedMemberId={user && user.siteMemberId}
          viewedMemberSlug={user && user.slug}
          stopPropagation={false}
        >
          <UserAvatarImage
            user={user}
            type={isAvatarSmall ? AvatarImage.SMALL : AvatarImage.MEDIUM}
            className={styles.avatar}
          />
        </MemberCardAsync>

        {withText && (
          <span className={styles.text}>
            {t(hasRecentActivity ? 'recent-activity.replied' : 'recent-activity.posted')}&nbsp;
          </span>
        )}
        <TimeAgo className={styles.timeAgo} time={date} />
        {withDirectionIcon && direction === DIRECTIONS.RTL && (
          <ArrowLeftIcon className={classNames('forum-icon-fill', styles.directionIcon)} />
        )}
        {withDirectionIcon && direction === DIRECTIONS.LTR && (
          <ArrowRightIcon className={classNames('forum-icon-fill', styles.directionIcon)} />
        )}
      </Link>
    );
  }
}

PostListItemRecentActivity.propTypes = {
  post: PropTypes.object,
  category: PropTypes.object,
  resolveCommentDeepLinkUrl: PropTypes.func,
  t: PropTypes.func,
  withDirectionIcon: PropTypes.bool,
  withText: PropTypes.bool,
  isAvatarSmall: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  resolveCommentDeepLinkUrl: actions.resolveCommentDeepLinkUrlPromisified,
});

export default flowRight(
  withErrorBoundary,
  connect(mapRuntimeToProps),
  withTranslate,
  withDirection,
)(PostListItemRecentActivity);
